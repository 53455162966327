<template>
      <a  v-editable="editing && blok" :href="blok.link.url"
         class="sb-icon"
                  :class="'icon-' + blok.type"
                  :target="blok.link.target"
                ></a>
 
</template>
<style lang="scss">
.sb-icon{
 display: inline-block;
              margin-right: 0.5rem;
              opacity: 0.7;
              line-height: 0px;
            //   line-height: ;
            vertical-align: middle;
              &::after {
                content: "";
                // mask-image:url('https://mdn.github.io/css-examples/masking/star.svg');

                // display: block;
                width: 1.5rem;
                display: inline-block;
                // line-height: 1em;;
                background-color: currentColor;
                aspect-ratio: 1 / 1;
              }
              &:hover {
                opacity: 1;
              }
              &.icon-e-mail {
                &::after {
                  mask-image: url("@/assets/images/ajv_icons_01.svg#icon-email");
                }
              }
              &.icon-linkedin {
                &::after {
                  mask-image: url("@/assets/images/ajv_icons_01.svg#icon-linkedin");
                }
              }
              &.icon-phone {
                &::after {
                  mask-image: url("@/assets/images/ajv_icons_01.svg#icon-phone");
                }
              }
            }
</style>
<script setup>
const { editing } = await useSettings();
const { blok } = defineProps({ blok: Object });
// console.log(blok)
//  useI18n();
//console.log(blok)


</script>
